import { store } from "@/store";
import { DEFINES } from "@/utils/defines";

export const questionnaireHelper = {
	async getChosenTheme() {
		let response = await store.dispatch("questionnaire/get", {
			questionnaire_name: DEFINES.QUESTIONNAIRE.q10,
		});
		if (!response) return null;

		let result = response.answers.find((answer) => answer.question.order === 1);
		if (!result) return null;

		return {
			question: result.question.name,
			answer: result.answer,
		};
	},

	async getResearchQuestion() {
		let response = await store.dispatch("questionnaire/get", {
			questionnaire_name: DEFINES.QUESTIONNAIRE.q51,
		});
		if (!response) return null;

		let result = response.answers.find((answer) => answer.question.order === 1);
		if (!result) return null;

		return {
			question: result.question.name,
			answer: result.answer,
			id: result.id,
		};
	},

	async getResearchTopic() {
		let response = await store.dispatch("questionnaire/get", {
			questionnaire_name: DEFINES.QUESTIONNAIRE.q51,
		});
		if (!response) return null;

		let result = response.answers.find((answer) => answer.question.order === 1);
		if (!result) return null;

		return {
			question: result.question.name,
			answer: result.answer,
			id: result.id,
		};
	},
};
